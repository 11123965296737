import React from "react";

export let PulsatingHeart = ({className}) => {
    return <svg className={`d-inline-block heartbeat text-danger ${className}`} width="52" height="38"
                viewBox="0 0 52 38"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M52,6.3c0.8-12.6-18.6-4-24.8,1.6c-0.4,0.4-1.1,0.4-1.5,0.1C9.9-2.7-2,1.5,0.3,10c1.5,5.6,16.2,20.4,25.5,27.7c0.4,0.3,1,0.3,1.4,0C36.7,29.5,51.2,19.1,52,6.3z"></path>
    </svg>
}

export let BackgroundWave = ({height = 450, zIndex = 'n1', background = 'secondary'}) => {
    return <div class={`position-absolute w-100 start-0 bottom-0 overflow-hidden p-0 z-${zIndex}`}>
        <div class={`text-${background}`} style={{width: '3840px', height: `${height}px`}}>
            <svg width="3840" height={`${height}`} viewBox={`0 0 3840 ${height}`} fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3840,32.1V450H0V32.1c72.5,0,112.1-17.4,271.6,5.5c199.3,28.5,206,34.7,378.4,29c172.4-5.7,287.8-34.5,418.9-34.5S1317,55.2,1430,48.4c113-6.8,176.5-37.9,248-45.9c15.4-1.7,33-2.6,51.8-2.6c68.4,0.1,166.7,14,223.1,23.4c84.8,6.6,128.1,27.8,288.5,43.5c199.8,19.5,156.2,5.5,328.6-0.2c172.4-5.7,287.8-34.5,418.9-34.5c131.1,0,248.1,23.1,361.1,16.3c113-6.8,176.5-37.9,248-45.9c15.4-1.7,33-2.6,51.8-2.6C3718.2,0,3800.9,11.2,3840,32.1z"></path>
            </svg>
        </div>
    </div>
}

export let InvertedBackgroundWave = () => {
    return <div class="position-absolute w-100 start-0 bottom-0 overflow-hidden p-0 z-n1"
                style={{transform: 'rotate(180deg)'}}>
        <div class="text-secondary" style={{width: '3840px', height: '450px'}}>
            <svg width="3840" height="450" viewBox="0 0 3840 450" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3840,32.1V450H0V32.1c72.5,0,112.1-17.4,271.6,5.5c199.3,28.5,206,34.7,378.4,29c172.4-5.7,287.8-34.5,418.9-34.5S1317,55.2,1430,48.4c113-6.8,176.5-37.9,248-45.9c15.4-1.7,33-2.6,51.8-2.6c68.4,0.1,166.7,14,223.1,23.4c84.8,6.6,128.1,27.8,288.5,43.5c199.8,19.5,156.2,5.5,328.6-0.2c172.4-5.7,287.8-34.5,418.9-34.5c131.1,0,248.1,23.1,361.1,16.3c113-6.8,176.5-37.9,248-45.9c15.4-1.7,33-2.6,51.8-2.6C3718.2,0,3800.9,11.2,3840,32.1z"></path>
            </svg>
        </div>
    </div>
}

export let BackgroundWave2 = ({className, paddingTop = 200, marginTop = 0}) => {
    return <div class={`position-absolute w-100 start-0 top-0 overflow-hidden p-0 z-n1 ${className}`}>
        <div className={"d-none d-md-block bg-secondary"} style={{height: paddingTop}}>
        </div>
        <div class="text-secondary" style={{width: '3840px', height: '450px', marginTop: marginTop}}>
            <svg width="3840" height="450" viewBox="0 0 3840 450" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg" style={{transform: 'rotate(180deg)'}}>
                <path
                    d="M3840,32.1V450H0V32.1c72.5,0,112.1-17.4,271.6,5.5c199.3,28.5,206,34.7,378.4,29c172.4-5.7,287.8-34.5,418.9-34.5S1317,55.2,1430,48.4c113-6.8,176.5-37.9,248-45.9c15.4-1.7,33-2.6,51.8-2.6c68.4,0.1,166.7,14,223.1,23.4c84.8,6.6,128.1,27.8,288.5,43.5c199.8,19.5,156.2,5.5,328.6-0.2c172.4-5.7,287.8-34.5,418.9-34.5c131.1,0,248.1,23.1,361.1,16.3c113-6.8,176.5-37.9,248-45.9c15.4-1.7,33-2.6,51.8-2.6C3718.2,0,3800.9,11.2,3840,32.1z"></path>
            </svg>
        </div>
    </div>
}