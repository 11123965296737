import React from "react";
import {CtaSection} from "@commons/ui/component/Section";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {PublicLinks} from "../../../../portal-latvia/src/utility/links";

let DoctorListCta = ({authentication}) => {
    return !authentication.isAuthenticated && <>
        <CtaSection
            className={"bg-secondary"}
            padding={"sm"}
            title={<FormattedMessage id={"pages.doctors.cta-bottom.title"}/>}
            text={<FormattedMessage id={"pages.doctors.cta-bottom.text"}/>}
            buttonLink={PublicLinks.registration.doctor()}
            buttonText={<FormattedMessage id={"pages.doctors.cta-bottom.button"}/>}
        />
    </>
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication
    }
};

export default connect(mapStateToProps)(DoctorListCta);
