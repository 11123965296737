import React from "react";

export let NumberedCard = ({number, title, text}) => {
    return <div className="position-relative d-flex flex-fill justify-content-end my-1 py-1">
        <div
            style={{top: '48px'}}
            className="btn btn-lg btn-icon btn-dark fs-xl fw-bold rounded-circle position-absolute start-0 translate-middle-y z-2 pe-none">

            {number}
        </div>
        <div className="card border-0 bg-secondary ps-3" style={{width: 'calc(100% - 1.75rem)'}}>
            <div className="card-body ps-4">
                <h3 className="h5 card-title pb-2 mb-1">
                    {title}
                </h3>
                <p className="card-text">
                    {text}
                </p>
            </div>
        </div>
    </div>
}