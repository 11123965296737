import React from "react";
import {RichText} from "@commons/infra/storyblok/richText";
import {SameBackgroundSectionV2} from "@commons/uinew/section/DifferentBackgroundSection";
import {transformTitle} from "@commons/infra/storyblok/helpers";

let Row3 = ({blok}) => {
    return <SameBackgroundSectionV2 className={"position-relative"}>
        <h2>
            {transformTitle(blok.Title)}
        </h2>
        <RichText content={blok.Content}/>
    </SameBackgroundSectionV2>
}

export default Row3
