import {Route} from "react-router-dom";
import PublicHeader from "../../common/component/PublicHeader";
import {FullHeightWrapper} from "@commons/uinew/layout/FullHeightWrapper";
import DoctorFooter from "../../doctor/component/DoctorFooter";
import React, {lazy} from "react";
import {PatientRestricted} from "../../App";

let ErgoSelectAppointmentPage = lazy(() => import("./selectappointment/ErgoSelectAppointmentPage"))
let ErgoAppointmentReservationPage = lazy(() => import("./selectappointment/ErgoAppointmentReservationPage"))

export let useErgoRoutes = () => {
    return <>
        <Route path={`/ergo/select-time`} element={<PatientRestricted>
            <PublicHeader shadow={false} showCta={false}/>
            <FullHeightWrapper>
                <ErgoSelectAppointmentPage/>
            </FullHeightWrapper>
            <DoctorFooter variant={'dark'}/>
        </PatientRestricted>}/>

        <Route path={`/ergo/appointments/:appointmentId/bookings/:bookingId/book`} element={<PatientRestricted>
            <PublicHeader shadow={false} showCta={false}/>
            <FullHeightWrapper>
                <ErgoAppointmentReservationPage/>
            </FullHeightWrapper>
            <DoctorFooter variant={'dark'}/>
        </PatientRestricted>}/>
    </>
}