import FaqAccordion from "@commons/ui/component/FaqAccordion";
import React from "react";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {H2} from "@commons/uinew/text/headings";

export let FaqSectionWithArrow = ({title, text, buttonText, showFaqLink, faqLink, faqItems, offset = 0}) => {
    return <div className="row mb-3 mb-lg-4">
        <div className={`offset-lg-${offset} col-lg-4`}>
            <H2>{title}</H2>
            <div className="pb-lg-4">
                {text}
            </div>
        </div>
        <div className={`col-md-8 col-lg-${7 - offset * 2} offset-lg-1`}>
            <div className="accordion" id="faq">
                <FaqAccordion items={faqItems}/>
            </div>
            {showFaqLink && <LocalizedLink to={faqLink} className={"btn btn-link mt-3"}>
                {buttonText} <i class={"ai-arrow-right ms-2"}/>
            </LocalizedLink>}
        </div>
    </div>
}