import {H2} from "@commons/uinew/text/headings";
import {FormattedMessage} from "react-intl";
import {PublicLinks} from "../../../../../portal-latvia/src/utility/links";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {DifferentBackgroundSection} from "@commons/uinew/section/DifferentBackgroundSection";
import React from "react";
import {DoctorsCarousel} from "@commons/ui/component/DoctorsCarousel";
import {useSpecialityFormatter} from "@commons/infra/helpers";
import {BackgroundWave} from "@commons/uinew/domaincomponent/svg";
import {StoryblokComponent} from "@storyblok/react";

export let Doctors = ({blok}) => {
    let specialityFormatter = useSpecialityFormatter()

    return <DifferentBackgroundSection className={`position-relative`}>
        <H2>
            <FormattedMessage id={"common.medon-specialists"}/>
        </H2>
        {blok.Text && <div className={`col-12`}>
            <div className={`mb-4 fs-lg`}>
                {blok.Text}
            </div>
        </div>}

        <DoctorsCarousel
            includeLinks={blok.IncludeLinks}
            doctors={blok.Doctors.map(json => {
                let item = JSON.parse(json)
                return {
                    link: PublicLinks.doctor(item.slug),
                    img: item.imgSrc,
                    name: item.name,
                    speciality: specialityFormatter.formatSpecialities(item.speciality[0])
                }
            })}
        />

        {blok.ShowShowAllLink && <div className="pt-1 pt-lg-2 text-center">
            <LocalizedLink to={PublicLinks.doctors()} className={"fs-lg fw-500"}>
                <FormattedMessage id={"pages.home.doctors.button-see-all"}/>
                <i className={"ai ai-arrow-right ms-1"}/>
            </LocalizedLink>
        </div>}

        {blok.BackgroundWave && blok.BackgroundWave.map(bw => <StoryblokComponent blok={bw} />)}
    </DifferentBackgroundSection>
}
