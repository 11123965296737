import {axios2} from "./axios";
import {AxiosRequestConfig} from "axios";

export let axiosProvider = <T>(
    config: AxiosRequestConfig,
    options?: AxiosRequestConfig,
): Promise<T> => {
    return axios2({
        ...config,
        ...options,
    })
}
