import React from "react";
import {FormattedMessage} from "react-intl";

export let BigStepsContainerSection = ({children}) => {
    return <section className="overflow-hidden pb-lg-5 mb-md-2 mb-xl-4 mb-xxl-5 mt-4 mt-lg-5">
        <div className="container py-2 py-sm-4 py-lg-5">
            {children}
        </div>
    </section>
}

export let BigStep = ({imgSrc, imgAlt, step, title, text, isImgOnRight = false}) => {
    let imgClass = isImgOnRight ? 'order-md-2 d-md-flex justify-content-end' : '';
    return <div className="row align-items-center position-relative pb-md-5 pb-lg-0 mb-1 mb-sm-2 mb-md-4 mb-lg-0">
        <div className={`col-md-6 col-xl-5 offset-lg-1 pb-2 pb-md-0 mb-4 mb-md-0 ${imgClass}`}>
            <img src={imgSrc} width="470" alt={imgAlt}/>
        </div>
        <div className="col-md-6 col-lg-5 col-xl-4 offset-xl-1 order-md-1">
            <div className="pe-md-4 pe-lg-0"><span
                className="badge fs-sm bg-faded-primary text-primary mb-3 mb-lg-4">
                <FormattedMessage id={"pages.how-it-works.step-x"} values={{step}}/>
            </span>
                <h3 className="h2 mb-3 mb-lg-4">{title}</h3>
                <p className="pb-1 pb-lg-0">
                    {text}
                </p>
            </div>
        </div>
    </div>;
}
export let BigStepArrowRight = () => {
    return <div className="d-none d-lg-flex justify-content-center" data-aos="fade-in" data-aos-duration="500"
                data-aos-offset="250">
        <svg className="d-block text-primary" width="339" height="365" viewBox="0 0 339 365" fill="none"
             xmlns="http://www.w3.org/2000/svg"
             style={{marginLeft: '-150px', marginTop: '-140px', marginBottom: '-108px'}}>
            <path d="M324 291.371C120.111 291.37 240.756 58.7225 1.00032 73.2606" stroke="url(#arrow1)"
                  stroke-width="2" stroke-linecap="round" stroke-dasharray="6 6"/>
            <path
                d="M337.375 290.62C338.074 290.998 338.074 292.001 337.375 292.379L328.476 297.196C327.81 297.557 327 297.074 327 296.317L327 286.683C327 285.925 327.81 285.443 328.476 285.803L337.375 290.62Z"
                fill="currentColor"/>
            <defs>
                <linearGradient id="arrow1" x1="324" y1="291.5" x2="-2.99974" y2="72.4997"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="currentColor"/>
                    <stop offset="1" stop-color="currentColor" stop-opacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    </div>
}
export let BigStepArrowLeft = () => {
    return <div className="d-none d-lg-flex justify-content-center" data-aos="fade-in" data-aos-duration="500"
                data-aos-offset="250">
        <svg className="d-block text-primary" width="263" height="275" viewBox="0 0 263 275" fill="none"
             xmlns="http://www.w3.org/2000/svg"
             style={{marginTop: '-60px', marginLeft: '-120px', marginBottom: '-80px'}}>
            <path
                d="M8.13678 249.647C7.47108 250.081 6.59001 249.602 6.59106 248.808L6.60444 238.689C6.60544 237.931 7.4158 237.45 8.08162 237.811L16.5478 242.408C17.2136 242.77 17.2512 243.712 16.6163 244.125L8.13678 249.647Z"
                fill="currentColor"/>
            <path
                d="M261.961 37.8891C216.908 65.6243 128.226 135.486 133.916 193.05C141.029 265.005 265.134 173.468 173.666 148.634C89.2542 125.715 30.9125 210.547 13.9796 236.702"
                stroke="url(#arrow2)" stroke-width="2" stroke-linecap="round" stroke-dasharray="6 6"/>
            <defs>
                <linearGradient id="arrow2" x1="13.9797" y1="234.5" x2="276.704" y2="60.1939"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="currentColor"/>
                    <stop offset="1" stop-color="currentColor" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    </div>
}