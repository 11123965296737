import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import React from "react";
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";
import {RichText} from "@commons/infra/storyblok/richText";
import {FullWidthSection1} from "@commons/ui/component/Section";

export let CardsContainer = ({blok}) => {
    return <FullWidthSection1 className={blok.BackgroundClass}>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4 pb-sm-3 pb-md-4 pb-xl-5">
            <RichText content={blok.Content}/>
        </div>
    </FullWidthSection1>
}

export let Card = ({blok}) => {
    return <div className="card-hover card-lifted">
        <div className={`card h-100 border-0 rounded-1 text-decoration-none px-xxl-1 bg-white`}>
            <div className="card-body px-3 py-2 px-md-4 py-3">
                <div className="d-flex flex-column align-items-stretch justify-content-between h-100">
                    <div className="ps-0 align-self-start">
                        <h4 className="fs-base mb-2 d-flex align-content-center">
                            <i className={"ai fs-xl ai-arrow-right text-primary me-2"}/>
                            {blok.Title}
                        </h4>
                    </div>
                    <div className={"text-start"} style={{flex: 1}}>
                        <RichText content={blok.Content}/>
                    </div>
                    <LocalizedLink className="btn btn-primary btn-sm" to={parseI18nLink(blok.ButtonLink)}>
                        {blok.ButtonText}
                        <i className={"ai ai-arrow-right ms-2"}/>
                    </LocalizedLink>
                </div>
            </div>
        </div>
    </div>
}
