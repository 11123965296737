import PageHeading1 from "@commons/infra/storyblok/components/PageHeading1";
import Page from "@commons/infra/storyblok/components/Page";
import Row1 from "@commons/infra/storyblok/components/Row1";
import VideoSwiper from "@commons/infra/storyblok/components/VideoSwiper";
import Cta from "@commons/infra/storyblok/components/Cta";
import SpecialityDoctorSwiper from "@commons/infra/storyblok/components/SpecialityDoctorSwiper";
import HeroWithVideoSection from "@commons/infra/storyblok/components/HeroWithVideoSection";
import Faq from "@commons/infra/storyblok/components/Faq";
import {Benefits} from "@commons/infra/storyblok/components/Benefits";
import {Steps} from "@commons/infra/storyblok/components/Steps";
import {Doctors} from "@commons/infra/storyblok/components/Doctors";
import HeroWithPictureSection from "@commons/infra/storyblok/components/HeroWithPictureSection";
import {RowV2} from "@commons/infra/storyblok/components/RowV2";
import {QaGameSponsorsSection} from "@commons/infra/storyblok/components/pagespecific/QaGameSponsorsSection";
import {LatvianStatisticsSectionStoryblokWrapper} from "@commons/infra/storyblok/components/LatvianStatisticsSection";
import {QuoteSectionStoryblokWrapper} from "@commons/infra/storyblok/components/QuoteSection";
import {
    LatvianPublicationsSwiperSectionStoryblokWrapper
} from "@commons/infra/storyblok/components/LatvianPublicationsSwiperSection";
import {CtaWithArrow} from "@commons/infra/storyblok/components/CtaWithArrow";
import CtaSimple1 from "@commons/infra/storyblok/components/CtaSimple1";
import {HorizontalStepsContainer} from "@commons/infra/storyblok/components/HorizontalStepsContainer";
import {CardsContainer} from "@commons/infra/storyblok/components/Card";
import Row3 from "@commons/infra/storyblok/components/Row3";
import {CardWithBlackNumber} from "@commons/infra/storyblok/components/CardWithBlackNumber";
import {Cards2Section} from "@commons/infra/storyblok/components/Cards2Section";
import {GreyBottomBackgroundWave1} from "@commons/infra/storyblok/components/backgroundwave/GreyBottomBackgroundWave1";
import {HowItWorksVideo} from "../../../../../portal-latvia/src/public/page/HowItWorksPage";
import Row1Video from "@commons/infra/storyblok/components/Row1Video";
import {
    HowItWorksVideoStoryblokComponent
} from "@commons/infra/storyblok/components/video/HowItWorksVideoStoryblokComponent";

let storyblokComponentsMapping = {
    SpecialityPage: Page,
    LandingPage: Page,

    HeroWithVideo: HeroWithVideoSection,
    HeroWithPicture: HeroWithPictureSection,
    PageHeading1: PageHeading1,
    Row1: Row1,
    Row1Video: Row1Video,
    RowV2: RowV2,
    Row3: Row3,
    VideoSwiper: VideoSwiper,
    Cta: Cta,
    CtaWithArrowSection: CtaWithArrow,
    CtaSimple1: CtaSimple1,
    HorizontalStepsContainer: HorizontalStepsContainer,
    FAQ: Faq,
    SpecialityDoctorSwiper: SpecialityDoctorSwiper,
    Benefits: Benefits,
    Steps: Steps,
    Doctors: Doctors,
    LatvianStatisticsSection: LatvianStatisticsSectionStoryblokWrapper,
    LatvianPublicationsSwiperSection: LatvianPublicationsSwiperSectionStoryblokWrapper,
    QuoteSection: QuoteSectionStoryblokWrapper,
    CardsContainer: CardsContainer,
    CardWithBlackNumber: CardWithBlackNumber,
    Cards2Section: Cards2Section,

    // PageSpecific
    QaGameSponsorsSection: QaGameSponsorsSection,

    // BackgroundWave
    GreyBottomBackgroundWave1: GreyBottomBackgroundWave1,

    // Row1Videos
    HowItWorksVideo: HowItWorksVideoStoryblokComponent,
}

export default storyblokComponentsMapping
