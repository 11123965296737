import {NumberedCard} from "@commons/uinew/component/cards";
import React from "react";
import {RichText} from "@commons/infra/storyblok/richText";

export let CardWithBlackNumber = ({blok}) => {
    return <NumberedCard
        number={blok.Number}
        title={blok.Title}
        text={<RichText content={blok.Content}/>}
    />
}