import {FullWidthSection2Video} from "@commons/ui/component/Section";
import React from "react";
import {RichText} from "@commons/infra/storyblok/richText";
import {transformTitle} from "@commons/infra/storyblok/helpers";
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";
import {StoryblokComponent} from "@storyblok/react";

let Row1Video = ({blok}) => {
    return <FullWidthSection2Video
        videoComponent={blok.VideoComponent.map(c => <StoryblokComponent blok={c}/>)}
        className={blok.BackgroundClass || (blok.GreyBackground ? "bg-secondary" : "")}
        hideImageOnMobile={blok.HideImageOnMobile}
        sidePaddingXMobile={blok.IllustrationPaddingXMobile}
        contentOnRightSide={blok.SidePosition === 'left'}
        title={transformTitle(blok.Title)}
        padding={blok.PaddingY}
        centerTitle={false}
        align={blok.Align}
        buttonText={blok.ButtonText}
        buttonLink={blok.ButtonLink && parseI18nLink(blok.ButtonLink)}
        buttonDisabledText={blok.ButtonDisabledText}
        content={<RichText content={blok.Content}/>}
        offsetCols={blok.Offset || 0}
    />
}

export default Row1Video
