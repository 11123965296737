/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import {Helmet} from "react-helmet";
import {title} from "../../App";
import {FormattedMessage, useIntl} from "react-intl";
import {PublicLinks} from "../../utility/links";
import {CtaSection} from "@commons/ui/component/Section";
import {LatvianMetaLinksHelmet} from "@commons/infra/i18n/MetaLinksHelmet";
import {VideoPlaceholder} from "@commons/ui/component/YouTubeVideo";
import lgVideo from "lightgallery/plugins/video";
import LightGallery from "lightgallery/react";
import {
    BigStep,
    BigStepArrowLeft,
    BigStepArrowRight,
    BigStepsContainerSection
} from "@commons/uinew/domainsection/steps/BigVerticalSteps";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {WaveBackgroundBottom} from "@commons/uinew/component/background";

let breakpoint = "md";

let HowItWorksPage = () => {
    let {formatMessage} = useIntl()
    return <>
        <Helmet>
            <title>{title(formatMessage({id: 'pages.how-it-works.page-title'}))}</title>
            <meta name="description"
                  content={formatMessage({id: 'pages.how-it-works.meta.description'})}/>
        </Helmet>
        <LatvianMetaLinksHelmet/>

        <div>
            <WaveBackgroundBottom>
                <section className="position-relative pt-lg-5 pb-md-6 pb-lg-7 overflow-hidden bg-secondary">
                    <div className="container mt-4 mt-lg-5 mt-md-7 pt-md-5 pt-lg-0">
                        <div className="row align-items-center">
                            <div className="col-lg-6 text-lg-start order-1 order-lg-0">
                                <h1 className="mb-3 text-center">
                                    <FormattedMessage id={"pages.how-it-works.header.1"}/>
                                </h1>
                                <p className="text-center fs-lg mb-4">
                                    <FormattedMessage id={"pages.how-it-works.header.2"}/>
                                </p>
                            </div>
                            <div
                                className={`offset-lg-1 col-lg-5 col-${breakpoint}-7 mb-3 mb-lg-0 mt-lg-4 mt-md-0 rounded-4 order-0 order-lg-1`}>

                                <HowItWorksVideo/>
                            </div>
                        </div>
                    </div>
                </section>
            </WaveBackgroundBottom>

            <BigStepsContainerSection>
                <BigStep imgSrc={"/img/how-it-works/1.png"}
                         imgAlt={"ALT"}
                         step={1}
                         title={<FormattedMessage id={"pages.how-it-works.block.1.title"}/>}
                         text={<FormattedMessage
                             id={"pages.how-it-works.block.1.text"}
                             values={{
                                 link: chunk => <LocalizedLink to={'#'}>
                                     {chunk}
                                 </LocalizedLink>,
                                 button: chunk => <div className={"mt-2"}>
                                     <LocalizedLink to={'#'}
                                                    className="btn btn-link ps-0">
                                         {chunk}
                                         <i className={"ai ai-arrow-right ms-2"}/>
                                     </LocalizedLink>
                                 </div>
                             }}
                         />}
                         isImgOnRight={true}/>

                <BigStepArrowRight/>

                <BigStep imgSrc={"/img/how-it-works/2.png"}
                         imgAlt={"ALT"}
                         step={2}
                         title={<FormattedMessage
                             id={"pages.how-it-works.block.2.title"}
                         />}
                         text={<FormattedMessage
                             id={"pages.how-it-works.block.2.text"}
                         />}
                         isImgOnRight={false}/>

                <BigStepArrowLeft/>

                <BigStep imgSrc={"/img/how-it-works/3.png"}
                         imgAlt={"ALT"}
                         step={3}
                         title={<FormattedMessage
                             id={"pages.how-it-works.block.3.title"}
                         />}
                         text={<FormattedMessage
                             id={"pages.how-it-works.block.3.text"}
                             values={{
                                 link: chunk => <LocalizedLink to={'#'}>
                                     {chunk}
                                 </LocalizedLink>
                             }}

                         />}
                         isImgOnRight={true}/>

                <BigStepArrowRight/>

                <BigStep imgSrc={"/img/how-it-works/4.png"}
                         imgAlt={"ALT"}
                         step={4}
                         title={<FormattedMessage
                             id={"pages.how-it-works.block.4.title"}
                         />}
                         text={<FormattedMessage
                             id={"pages.how-it-works.block.4.text"}
                         />}
                         isImgOnRight={false}/>

            </BigStepsContainerSection>

            <CtaSection
                className={"bg-secondary"}
                title={<FormattedMessage id={"pages.how-it-works.cta.title"} values={{br: ' '}}/>}
                text={<FormattedMessage id={"pages.how-it-works.cta.text"}/>}
                buttonLink={PublicLinks.registration.patient()}
                buttonText={<FormattedMessage id={"pages.how-it-works.cta.button"}/>}
            />
        </div>
    </>
};

export let HowItWorksVideo = () => {
    return <LightGallery
        plugins={[lgVideo]}
        videojs={false}
        enableDrag={false}
        controls={false}
        counter={false}
        download={false}
        escKey={true}
        autoplayFirstVideo={true}
        forceSlideShowAutoplay={true}
        youTubePlayerParams={{
            modestbranding: 1, showinfo: 0, ref: 0, mute: 0, autoplay: 0
        }}>
        <a href="/#"
           className={"hover-text-decoration-none"}
           data-lg-size="1280-720"
           data-src={"https://www.youtube.com/watch?v=jl9UPC46rlU"}>
            <VideoPlaceholder
                imgWidth={500}
                imgHeight={322}
                placeholderImageSrc={'/img/how-it-works/how-it-works-banner.png'}
                backgroundSize={'cover'}
                linkProps={{
                    "data-src": 'https://www.youtube.com/watch?v=6OHTvBhwHdo'
                }}
            />
        </a>
    </LightGallery>
}

export default HowItWorksPage;
