import {FullWidthSection2} from "@commons/ui/component/Section";
import React from "react";
import {RichText} from "@commons/infra/storyblok/richText";
import {extractImageDimensions, transformTitle} from "@commons/infra/storyblok/helpers";
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";

let Row1 = ({blok}) => {
    let imgDimensions = extractImageDimensions(blok.Illustration.filename)

    return <FullWidthSection2
        imgSrc={blok.Illustration.filename}
        imgAlt={blok.Illustration.alt}
        imgHeight={imgDimensions.height}
        imgWidth={imgDimensions.width}
        imgPaddingXMobile={blok.IllustrationPaddingXMobile}
        imgClassName={"object-fit-contain"}
        className={blok.BackgroundClass || (blok.GreyBackground ? "bg-secondary" : "")}
        hideImageOnMobile={blok.HideImageOnMobile}
        contentOnRightSide={blok.IllustrationPosition === 'left'}
        title={transformTitle(blok.Title)}
        padding={blok.PaddingY}
        centerTitle={false}
        align={blok.Align}
        buttonText={blok.ButtonText}
        buttonLink={blok.ButtonLink && parseI18nLink(blok.ButtonLink)}
        buttonDisabledText={blok.ButtonDisabledText}
        content={<RichText content={blok.Content}/>}
        offsetCols={blok.Offset || 0}
    />
}

export default Row1
