import {useLocation} from "react-router-dom";
import {useEffect} from "react";

let RESTORE_SCROLL_ON = [
    '/arstu-specialitates',
    '/ru/specialnosti-vrachei',
    '/en/doctor-specialities',
]

export let ScrollPositionSaver = () => {
    const { pathname, location } = useLocation();

    useEffect(() => {
        return () => {
            // console.debug("ScrollPositionSaver: Saving scroll position");
            sessionStorage.setItem(`scrollPosition-${pathname}`, window.scrollY);
        };
    }, [pathname]);

    useEffect(() => {
        const handleBackButton = () => {
            let currentPathname = new URL(document.location).pathname
            if (!RESTORE_SCROLL_ON.find(s => currentPathname.startsWith(s))) {
                return
            }

            const savedPosition = sessionStorage.getItem(`scrollPosition-${pathname}`);
            if (savedPosition) {
                // console.debug("ScrollPositionSaver: Restoring scroll position", currentPathname);
                window.scrollTo(0, parseInt(savedPosition, 10));
            }
            // console.debug("ScrollPositionSaver: User clicked the Back button!", currentPathname);
        };

        window.addEventListener("popstate", handleBackButton);

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, []);

    return null
}
