import {DEFAULT_BREAKPOINTS_SM_2, defaultSwiperSettings, SwiperWrapper} from "@commons/uinew/component/swiper";
import {CtaLink} from "@commons/uinew/domaincomponent/cta";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import React from "react";
import LazySwiper from "@commons/uinew/component/swiper/LazySwiper";

export let SpecialitiesSwiperSection = ({items, ctaText, ctaLink}) => {
    return <>
        <SwiperWrapper>
            <LazySwiper
                {...defaultSwiperSettings()}
                wrapperClass={"pt-2 pb-3"}
                slideClassName={"d-flex h-100"}
                spaceBetween={30}
                breakpoints={DEFAULT_BREAKPOINTS_SM_2}
                items={items.map(item => <SpecialityCard
                    imgSrc={item.imgSrc}
                    imgAlt={item.imgAlt}
                    link={item.link}
                    title={item.title}
                    text={item.text}
                    style={item.style}
                />)}
            />
        </SwiperWrapper>

        {ctaText && <CtaLink text={ctaText} link={ctaLink}/>}
    </>
}

let SpecialityCard = ({imgSrc, imgAlt, link, title, text, style = {}}) => {
    return <LocalizedLink to={link} className={"link-normal text-decoration-none"}>
        <div className="card card-hover card-lifted border-0 shadow mx-auto bg-white h-100 d-flex">
            <div className={"d-flex justify-content-center align-items-center rounded-top bg-secondary"}
                 style={{height: '180px'}}>
                <img className="card-img-top"
                     src={imgSrc}
                     alt={imgAlt}
                     style={{maxWidth: '120px', maxHeight: '120px', height: 'auto', width: 'auto', ...style}}/>
            </div>
            <div className="card-body">
                <h6 className="card-title">
                    {title}
                </h6>
                <p className={"card-text"}>
                    {text}
                </p>
            </div>
        </div>
    </LocalizedLink>
}