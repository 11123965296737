import React, {lazy} from "react";

let LazySwiperInner = lazy(() => import("@commons/uinew/component/swiper/LazySwiperInner"));

let LazySwiper = ({_ref, items, slideClassName, ...props}) => {
    return <LazySwiperInner
        _ref={_ref}
        items={items}
        slideClassName={slideClassName}
        {...props}
    />
}

export default LazySwiper