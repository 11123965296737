import {urlToDoctorProfilePhoto, useSpecialityFormatter} from "@commons/infra/helpers";
import React, {useEffect, useState} from "react";
import {DoctorsCarousel} from "@commons/ui/component/DoctorsCarousel";
import {DoctorApi} from "@commons/api/public/DoctorApi";
import {CommonPatientLinks} from "@commons/infra/links";
import {FullWidthSection1} from "@commons/ui/component/Section";

export let DoctorsCarouselWrapper = ({className, title, specialityCodes}) => {
    let {formatSpecialities} = useSpecialityFormatter();
    let [doctors, setDoctors] = useState();

    useEffect(() => {
        DoctorApi.getDoctors({speciality: specialityCodes, order: 'BY_SPECIALITY'})
            .then(r => setDoctors(r.data.doctors))
    }, [setDoctors, specialityCodes])

    return doctors && <FullWidthSection1 className={`${className}`}
                                         rowClass={"justify-content-center g-3"}
                                         title={title}>
        <DoctorsCarousel
            doctors={doctorsFromResponse(doctors, formatSpecialities)}
        />
    </FullWidthSection1>
}

let doctorsFromResponse = (responseDoctors, formatSpecialities) => {
    return responseDoctors.map(d => ({
        link: CommonPatientLinks.doctor.doctor(d.slug),
        img: urlToDoctorProfilePhoto(d.profilePhoto),
        name: d.fullName,
        speciality: formatSpecialities(d.specialities)
    }))
}