import {FormattedMessage} from "react-intl";
import {FaqSectionWithArrow} from "@commons/ui/section/FaqSection";
import React from "react";
import {PublicLinks} from "../../../../../portal-latvia/src/utility/links";
import {DifferentBackgroundSection} from "@commons/uinew/section/DifferentBackgroundSection";
import {RichText} from "@commons/infra/storyblok/richText";

let Faq = ({blok}) => {
    return <DifferentBackgroundSection className={blok.BackgroundClass}>
            <FaqSectionWithArrow
                title={blok.Title}
                text={<RichText content={blok.Text}/>}
                faqItems={blok.Items.map(i => ({
                    hash: i._uid,
                    titleMessage: {id: `${i.Item}.title`},
                    textMessage: {id: `${i.Item}.text`},
                }))}
                showFaqLink={blok.ShowAllLink}
                buttonText={<FormattedMessage id={"pages.doctor.faq-section.see-more"}/>}
                faqLink={PublicLinks.faq()}
                offset={blok.Offset}
            />
        </DifferentBackgroundSection>
}

export default Faq