import React from 'react';
import Logo from "@commons/ui/component/Logo";
import {getPrivacyPolicyUrl, termsAndConditionsUrl} from "@commons/infra/helpers";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {FormattedMessage, useIntl} from "react-intl";
import {PublicLinks, StoryblokLinks} from "../../utility/links";
import {useStoryblokMapping} from "@commons/infra/storyblok/data";
import {
    SB_SPECIALITY_DERMATOLOGS,
    SB_SPECIALITY_ENDOKRINOLOGS,
    SB_SPECIALITY_GIMENES_ARSTS,
    SB_SPECIALITY_GINEKOLOGS,
    SB_SPECIALITY_KARDIOLOGS,
    SB_SPECIALITY_NEIROLOGS,
    SB_SPECIALITY_ONKOLOGS,
    SB_SPECIALITY_PEDIATRS,
    SB_SPECIALITY_REIMATOLOGS,
    SB_SPECIALITY_UROLOGS,
    SB_SPECIALITY_UZTURA_SPECIALISTS
} from "@commons/infra/storyblok/consts";

export let copyrightText = <FormattedMessage id={"common.copyright"} values={{
    copy: <>&copy;</>,
    year: new Date().getFullYear()
}}/>;


let STORYBLOK_SPECIALITIES = [
    SB_SPECIALITY_DERMATOLOGS,
    SB_SPECIALITY_ENDOKRINOLOGS,
    SB_SPECIALITY_GIMENES_ARSTS,
    SB_SPECIALITY_GINEKOLOGS,
    SB_SPECIALITY_KARDIOLOGS,
    SB_SPECIALITY_NEIROLOGS,
    SB_SPECIALITY_ONKOLOGS,
    SB_SPECIALITY_PEDIATRS,
    SB_SPECIALITY_REIMATOLOGS,
    SB_SPECIALITY_UROLOGS,
    SB_SPECIALITY_UZTURA_SPECIALISTS
]

const PublicFooter = () => {
    let breakpoint = 'md'
    let {locale} = useIntl()
    let storyblokMapping = useStoryblokMapping()

    return <>
        <footer className={`footer bg-dark pt-3 pt-${breakpoint}-5 pt-md-6`}>
            <div className="container pt-3 pt-md-0">
                <div className="row pb-3">

                    <div className="col-md-3 mt-n2 pb-md-0 text-center text-md-start">
                        <LocalizedLink className="d-block mb-3 mx-auto mx-md-0" to="/">
                            <Logo theme="white" className={"me-0"} lazyLoading={true}/>
                        </LocalizedLink>

                        <div className={"fs-sm text-light opacity-60 pb-2 pb-sm-3"}>
                            <div className="mb-1 d-flex justify-content-center justify-content-md-start">
                                <div className="text-light">info@medon.lv</div>
                            </div>
                            <div className="mb-1 d-flex justify-content-center justify-content-md-start">
                                <div className="text-light">+371 26 005 609</div>
                            </div>
                            <div className={"mb-1 d-flex justify-content-center justify-content-md-start"}>

                                Ārstniecības iestādes kods:<br/>{window.CONFIG.APP.medicalInstitution.code}
                            </div>
                        </div>

                        <SocialButton social={"facebook"} link={"https://www.facebook.com/medon.lv"}/>
                        <SocialButton social={"instagram"} link={"https://www.instagram.com/medon.lv/"}/>
                        <SocialButton social={"linkedin"} link={"https://www.linkedin.com/company/medon-lv/"}/>
                    </div>

                    <div className="col-md-3 pt-3 pt-sm-0 mt-md-n2 pb-md-0 text-center text-md-start">
                        <h6 className='text-light mb-3'>
                            <FormattedMessage id={"components.public-footer.information"}/>
                        </h6>
                        <div className={"fs-sm text-light opacity-60 pb-2 pb-sm-3"}>
                            <div className="mb-1">
                                <LocalizedLink to={PublicLinks.howItWorksForDoctors()} style={{color: 'white'}}>
                                    <FormattedMessage id={"components.public-footer.for-doctors"}/>
                                </LocalizedLink>
                            </div>
                            <div className="mb-1">
                                <LocalizedLink to={PublicLinks.sirdsVeselibasTests()} style={{color: 'white'}}>
                                    <FormattedMessage id={"components.public-footer.sirds-veselibas-tests"}/>
                                </LocalizedLink>
                            </div>
                            <div className="mb-1">
                                <LocalizedLink to={PublicLinks.foreignPatients()} style={{color: 'white'}}>
                                    <FormattedMessage id={"components.public-footer.for-international-patients"}/>
                                </LocalizedLink>
                            </div>
                            <div className="mb-1">
                                <a className={"hide-external-icon"} style={{color: 'white'}}
                                   href={termsAndConditionsUrl()} target={"_blank"}
                                   rel="noopener noreferrer">
                                    <FormattedMessage id={"components.public-footer.terms-and-conditions"}/>
                                </a>
                            </div>
                            <div className="mb-1">
                                <a className={"hide-external-icon"} style={{color: 'white'}}
                                   href={getPrivacyPolicyUrl(locale)} target={"_blank"}
                                   rel="noopener noreferrer">
                                    <FormattedMessage id={"components.public-footer.privacy-policy"}/>
                                </a>
                            </div>
                            <div className="mb-1">
                                <LocalizedLink to={PublicLinks.contacts()} style={{color: 'white'}}>
                                    <FormattedMessage id={"components.public-footer.contacts"}/>
                                </LocalizedLink>
                            </div>
                            <div className="mb-1">
                                <LocalizedLink to={PublicLinks.internetAptieka()} style={{color: 'white'}}>
                                    InternetAptieka.lv
                                </LocalizedLink>
                            </div>
                            <div className="mb-1">
                                <LocalizedLink to={StoryblokLinks.myFitness()} style={{color: 'white'}}>
                                    MyFitness
                                </LocalizedLink>
                            </div>
                            <div className="mb-1">
                                <LocalizedLink to={"https://blog.medon.lv"} style={{color: 'white'}}>
                                    <FormattedMessage id={"components.public-header.blog"}/>
                                </LocalizedLink>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 pt-3 pt-sm-0 mt-md-n2 pb-md-0 text-center text-md-start">
                        <h6 className='text-light mb-3'>
                            <FormattedMessage id={"components.public-footer.doctor-specialities"}/>
                        </h6>
                        <div className={"fs-sm text-light opacity-60 pb-2 pb-sm-3"}>
                            {STORYBLOK_SPECIALITIES.map(slug => {
                                let hi = storyblokMapping.getSpeciality(slug)
                                return <div className="mb-1">
                                    <LocalizedLink to={hi.link} style={{color: 'white'}}>
                                        {hi.title}
                                    </LocalizedLink>
                                </div>
                            })}
                            <LocalizedLink to={PublicLinks.storyblokSpecialities()} style={{color: 'white'}}>
                                <FormattedMessage id={"components.public-header.view-all-specialities"}/>
                            </LocalizedLink>
                        </div>
                    </div>

                    <div className="col-md-3 pt-3 pt-sm-0 mt-md-n2 pb-md-0 text-center text-md-start">
                        <h6 className='text-light mb-3'>
                            <FormattedMessage id={"components.public-footer.health-issues"}/>
                        </h6>
                        <div className={"fs-sm text-light opacity-60 pb-2 pb-sm-3"}>
                            {storyblokMapping.getHealthIssues().map(hi => <div className="mb-1">
                                <LocalizedLink to={hi.link} style={{color: 'white'}}>
                                    {hi.title}
                                </LocalizedLink>
                            </div>)}
                            <LocalizedLink to={PublicLinks.storyblokHealthIssues()} style={{color: 'white'}}>
                                <FormattedMessage id={"components.public-header.view-all-health-issues"}/>
                            </LocalizedLink>
                        </div>
                    </div>
                </div>

                <hr className="border-light"/>

                <div className="row align-items-center mt-1 pt-4 pb-4">
                    <div className="col-md-6 offset-md-3">
                        <p className="fs-sm text-center mb-0">
                          <span className="text-light opacity-50 me-1">
                            {copyrightText}
                          </span>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    </>
};

let SocialButton = ({social, link}) => {
    return <a
        className={`btn btn-dark btn-outline-light btn-icon btn-sm btn-${social} bs-lg me-2 mb-2 hide-external-icon`}
        href={link}
        target={"_blank"} rel="noopener noreferrer"
        aria-label={`${social} page`}
    >

        <i className={`ai-${social}`}/>
    </a>
}

export default PublicFooter;
