import React from "react";
import {getPrivacyPolicyUrl, termsAndConditionsUrl} from "@commons/infra/helpers";
import {copyrightText} from "../../common/component/PublicFooter";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {FormattedMessage} from "react-intl";
import {PublicLinks} from "../../utility/links";

let DoctorFooter = ({variant = 'light'}) => {
    let containerClass, textClass, linkClass
    if (variant === 'light') {
        containerClass = "bg-secondary"
        textClass = "text-muted"
        linkClass = "text-muted"
    } else {
        containerClass = "bg-dark"
        textClass = "text-light opacity-60"
        linkClass = "text-light opacity-60"
    }

    return <footer className={`footer py-4 ${containerClass}`}>
        <div className="container d-md-flex align-items-center justify-content-between py-2 text-center text-md-start">
            <ul className="list-inline fs-sm mb-3 mb-md-0 order-md-2">
                <li className="list-inline-item my-1">
                    <LocalizedLink className={`${linkClass}`} to={PublicLinks.contacts()}>
                        <FormattedMessage id={'components.public-footer.contacts'}/>
                    </LocalizedLink>
                </li>
                <li className="list-inline-item my-1">
                    <a className={`${linkClass}`} target={"_blank"} rel="noopener noreferrer"
                       href={termsAndConditionsUrl()}>
                        <FormattedMessage id={'components.public-footer.terms-and-conditions'}/>
                    </a>
                </li>
                <li className="list-inline-item my-1">
                    <a className={`${linkClass}`} target={"_blank"} rel="noopener noreferrer"
                       href={getPrivacyPolicyUrl('lv')}>
                        <FormattedMessage id={'components.public-footer.privacy-policy'}/>
                    </a>
                </li>
            </ul>
            <p className="fs-sm mb-0 me-3 order-md-1">
                <span className={`${textClass} me-1`}>{copyrightText}</span>
            </p>
        </div>
    </footer>
}

export default DoctorFooter;
