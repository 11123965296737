import {Navigate, Route} from "react-router-dom";
import React, {lazy} from "react";
import PublicHeader from "../../../common/component/PublicHeader";
import {PatientOnboardingLinks} from "./links";
import {PatientRestricted} from "../../../App";
import PublicFooter from "../../../common/component/PublicFooter";
import {AUTHENTICATION_PURPOSE__PATIENT_AUTHENTICATION} from "@commons/infra/consts";
import {useLocalizedRoutes} from "@commons/infra/i18n/routes";
import {useLocalizedRoute} from "@commons/infra/i18n/LocalizedLink";

let RecaptchaAware = lazy(() => import("@commons/infra/thirdparty/recaptcha").then(module => ({default: module.RecaptchaAware})))
let Step1 = lazy(() => import("./Step1").then(module => ({default: module.Step1})))
let Step2 = lazy(() => import("./Step2").then(module => ({default: module.Step2})))
let Step1EmailConfirmation = lazy(() => import("./Step1EmailConfirmation").then(module => ({default: module.Step1EmailConfirmation})))
let MinimalisticFooter = lazy(() => import("@commons/uinew/domainsection/footer/MinimalisticFooter").then(module => ({default: module.MinimalisticFooter})));
let MobilePhoneVerificationStep1 = lazy(() => import("./phonenumberverification/MobilePhoneVerificationStep1").then(module => ({default: module.MobilePhoneVerificationStep1})));
let PatientRegistrationStep1Page = lazy(() => import("../../../public/page/patientregistration/PatientRegistrationStep1Page"));
let MobilePhoneVerificationStep2 = lazy(() => import("./phonenumberverification/MobilePhoneVerificationStep2"));
let PatientOnboardingController = lazy(() => import("./PatientOnboardingController"));
let FullHeightWrapper = lazy(() => import("@commons/uinew/layout/FullHeightWrapper").then(module => ({default: module.FullHeightWrapper})));

export let usePatientRegistrationRoutes = () => {
    let {getTemplate} = useLocalizedRoutes()
    let localizedRoute = useLocalizedRoute();

    return <>
        {/* TODO extract PublicLayout */}

        <Route path="/new-registration/1" element={<>
            <Navigate to={localizedRoute.create({id: "patient-registration"})} replace={true}/>
        </>}/>

        <Route path={getTemplate("patient-registration")} element={<>
            <PublicHeader shadow={false} showCta={false}/>
            <RecaptchaAware>
                <Step1/>
            </RecaptchaAware>
            <MinimalisticFooter/>
        </>}/>

        <Route path="/new-registration/1/confirm-email" element={<>
            <PublicHeader shadow={false} showCta={false}/>
            <FullHeightWrapper>
                <Step1EmailConfirmation/>
            </FullHeightWrapper>
            <MinimalisticFooter/>
        </>}/>

        <Route path="/new-registration/finalize" element={<>
            <PublicHeader shadow={false} showCta={false}/>
            <FullHeightWrapper>
                <Step2/>
            </FullHeightWrapper>
            <MinimalisticFooter/>
        </>}/>

        <Route path={PatientOnboardingLinks.onboarding.phoneNumberVerification.step1()} element={<>
            <PublicHeader shadow={false} showCta={false}/>
            <FullHeightWrapper>
                <MobilePhoneVerificationStep1/>
            </FullHeightWrapper>
            <PublicFooter/>
        </>}/>

        <Route path={'/onboarding/phone-number/verify'} element={<>
            <PublicHeader shadow={false} showCta={false}/>
            <FullHeightWrapper>
                <MobilePhoneVerificationStep2/>
            </FullHeightWrapper>
            <PublicFooter/>
        </>}/>

        <Route path={PatientOnboardingLinks.onboarding.authentication()} element={<>
            <PublicHeader shadow={false} showCta={false}/>
            <PatientRegistrationStep1Page authenticationPurpose={AUTHENTICATION_PURPOSE__PATIENT_AUTHENTICATION}/>
            <PublicFooter/>
        </>}/>

        <Route path={'/new-registration/onboarding-controller'} element={<>
            <PublicHeader shadow={false} showCta={false}/>
            <PatientRestricted>
                <FullHeightWrapper>
                    <PatientOnboardingController/>
                </FullHeightWrapper>
            </PatientRestricted>
            <PublicFooter/>
        </>}/>
    </>
}
