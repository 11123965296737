import React, {useCallback, useEffect, useRef, useState} from "react";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import LazySwiper from "@commons/uinew/component/swiper/LazySwiper";
import {defaultSwiperSettings} from "@commons/uinew/component/swiper";

export let DoctorsCarousel = ({doctors, includeLinks = true}) => {
    // let swiperRef = useRef();
    //
    // const handlePrev = useCallback(() => {
    //     if (!swiperRef.current) return;
    //     swiperRef.current.swiper.slidePrev();
    // }, []);
    //
    // const handleNext = useCallback(() => {
    //     if (!swiperRef.current) return;
    //     swiperRef.current.swiper.slideNext();
    // }, []);
    //
    // let [paginationClassName, setPaginationClassName] = useState();
    //
    // useEffect(() => {
    //     if (doctors.length > 4) {
    //         setPaginationClassName("d-block")
    //     } else if (doctors.length > 3) {
    //         setPaginationClassName("d-block d-lg-none")
    //     } else if (doctors.length > 2) {
    //         setPaginationClassName("d-block d-md-none")
    //     } else if (doctors.length > 1) {
    //         setPaginationClassName("d-block d-sm-none")
    //     }
    // }, [doctors, setPaginationClassName])

    return <>
        <LazySwiper
            {...defaultSwiperSettings()}
            slidesPerView={1.2}
            spaceBetween={20}
            // cssMode={true} // fixes slowness in Chrome mobile https://github.com/nolimits4web/swiper/issues/5743
            breakpoints={{
                576: { // sm
                    slidesPerView: 2.2,
                    spaceBetween: 20,
                },
                768: { // md
                    slidesPerView: 3.2,
                    spaceBetween: 20,
                },
                992: { // lg
                    slidesPerView: 4.2,
                    spaceBetween: 20,
                },
            }}
            items={doctors.map(doctor => <DoctorCard link={doctor.link}
                                                     img={doctor.img}
                                                     name={doctor.name}
                                                     speciality={doctor.speciality}
                                                     includeLinks={includeLinks}/>
            )}
        />
    </>
}

export let DoctorCard = ({link, img, name, speciality, includeLinks}) => {
    let className = "card border-0 shadow mx-auto mh-100 h-100 flex-fill"
    return includeLinks
        ? <LocalizedLink className={className} to={link}>
            <DoctorCardContents img={img} name={name} speciality={speciality}/>
        </LocalizedLink>
        : <div className={className}>
            <DoctorCardContents img={img} name={name} speciality={speciality}/>
        </div>
}

let DoctorCardContents = ({img, name, speciality}) => {
    return <>
        <img className="card-img-top"
             src={img}
             alt={`${name}, ${speciality}`}/>
        <div className="card-body h-100">
            <strong className="h5">
                {name}
            </strong><br/>
            <span className="text-muted fw-light">
                {speciality}
            </span>
        </div>
    </>;
}