import {Route} from "react-router-dom";
import PublicHeader from "../../common/component/PublicHeader";
import {FullHeightWrapper} from "@commons/uinew/layout/FullHeightWrapper";
import DoctorFooter from "../../doctor/component/DoctorFooter";
import React, {lazy} from "react";
import {PatientRestricted} from "../../App";
import {StoryblokSpecificLandingPage} from "@commons/ui/page/public/storyblok/StoryblokLandingPage";

let CompanySelectAppointmentPage = lazy(() => import("./selectappointment/CompanySelectAppointmentPage"))

export let useExpressGpCompanyRoutes = () => {
    return <>
        <Route path={`/uznemumiem`} element={<>
            <StoryblokSpecificLandingPage landingId={"uznemumiem"} slugPrefix={"landings/"}/>
        </>}/>

        <Route path={`/darbiniekiem`} element={<>
            <StoryblokSpecificLandingPage landingId={"uznemumiem-pieteiksanas"} slugPrefix={"landings/"}/>
        </>}/>

        <Route path={`/company/select-time`} element={<PatientRestricted>
            <PublicHeader shadow={false} showCta={false}/>
            <FullHeightWrapper>
                <CompanySelectAppointmentPage/>
            </FullHeightWrapper>
            <DoctorFooter variant={'dark'}/>
        </PatientRestricted>}/>
    </>
}