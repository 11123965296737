import {Route} from "react-router-dom";
import PublicHeader from "../../common/component/PublicHeader";
import {FullHeightWrapper} from "@commons/uinew/layout/FullHeightWrapper";
import DoctorFooter from "../../doctor/component/DoctorFooter";
import React, {lazy} from "react";
import {PatientRestricted} from "../../App";

let SelectAppointmentPage = lazy(() => import("./selectappointment/SelectAppointmentPage"))
let AppointmentReservationPage = lazy(() => import("./selectappointment/AppointmentReservationPage"))

export let useBalciaRoutes = () => {
    return <>
        <Route path={`/balcia/select-time`} element={<PatientRestricted>
            <PublicHeader shadow={false} showCta={false}/>
            <FullHeightWrapper>
                <SelectAppointmentPage/>
            </FullHeightWrapper>
            <DoctorFooter variant={'dark'}/>
        </PatientRestricted>}/>

        <Route path={`/balcia/appointments/:appointmentId/bookings/:bookingId/book`} element={<PatientRestricted>
            <PublicHeader shadow={false} showCta={false}/>
            <FullHeightWrapper>
                <AppointmentReservationPage/>
            </FullHeightWrapper>
            <DoctorFooter variant={'dark'}/>
        </PatientRestricted>}/>
    </>
}