import {TickedListItem, TickedListItemV2, TickedListItemWithoutTitle} from "@commons/ui/component/UnorderedList";
import React from "react";
import YouTubeVideo from "@commons/ui/component/YouTubeVideo";
import {
    MARK_LINK,
    MARK_TEXT_STYLE,
    NODE_HEADING,
    NODE_LI,
    NODE_OL,
    NODE_PARAGRAPH,
    NODE_UL,
    render
} from 'storyblok-rich-text-react-renderer';
import {TARGET_BLANK_PROPS} from "@commons/infra/helpers";
import {HorizontalStepsItem} from "@commons/infra/storyblok/components/HorizontalStepsItem";
import {Card} from "@commons/infra/storyblok/components/Card";
import {CardWithBlackNumber} from "@commons/infra/storyblok/components/CardWithBlackNumber";
import {CardWithBlackNumberContainers} from "@commons/infra/storyblok/components/CardWithBlackNumberContainers";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";

export let RichText = ({content, contentInserter = () => <></>, headingInserter = () => <></>}) => {
    let headingsCounter = 0
    let paragraphsCounter = 0

    return <>{render(content, {
        blokResolvers: {
            ['YoutubeVideo']: (props) => <div className={"mb-4"}>
                <YouTubeVideo
                    youtubeVideoId={props.VideoId}
                    placeholderVideoId={props.VideoId}
                />
            </div>,
            ['TickedListItem']: (props) => <TickedListItem
                title={props.Title}
                compensateInnerParagraph={true} // RichText field wraps things into <p> automatically
                text={<RichText content={props.Text}/>}
            />,
            ['TickedListItemV2']: (props) => <TickedListItemV2
                title={props.Title}
                compensateInnerParagraph={true} // RichText field wraps things into <p> automatically
                text={<RichText content={props.Text}/>}
            />,
            ['HorizontalStepsItem']: (props) => <HorizontalStepsItem blok={props}/>,
            ['Card']: (props) => <Card blok={props}/>,
            ['CardWithBlackNumberContainer']: (props) => <CardWithBlackNumberContainers blok={props}/>,
            ['CardWithBlackNumber']: (props) => <CardWithBlackNumber blok={props}/>,
        },
        markResolvers: {
            [MARK_LINK]: (children, {linktype, href, target, anchor, uuid, custom}) => {
                if (href.startsWith("$")) {
                    return <LocalizedLink to={parseI18nLink(href)}>
                        {children}
                    </LocalizedLink>
                }

                let isExternal = href.startsWith("https://") && !href.startsWith(window.CONFIG.FRONTEND_BASE_URL);

                return <a href={href} {...isExternal ? TARGET_BLANK_PROPS : {}}>
                    {children}
                </a>
            },
            [MARK_TEXT_STYLE]: (children, props) => {
                delete props['color'] // Removes text of different color after copy-paste
                return children
            }
        },
        nodeResolvers: {
            [NODE_PARAGRAPH]: (children) => {
                paragraphsCounter++;
                return <>
                    <p>{children}</p>
                    {contentInserter(paragraphsCounter)}
                </>
            },
            [NODE_HEADING]: (children, {level}) => {
                headingsCounter++

                return <>
                    {headingInserter(headingsCounter)}
                    {level === 2 && <h3 className={"mt-4"}>{children}</h3>}
                    {level === 3 && <h5 className={"mt-2"}>{children}</h5>}
                </>
            },
            [NODE_OL]: (children) => <div className={"mb-3"}>{children}</div>,
            [NODE_UL]: (children) => <div className={"mb-3"}>{children}</div>,
            [NODE_LI]: (children) => <TickedListItemWithoutTitle
                className={"mb-0"}
                icon={"ai-arrow-right"}>
                {children}
            </TickedListItemWithoutTitle>,
        }
    })}</>
}