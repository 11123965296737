import React, {useEffect, useState} from "react";
import {Radio} from "@commons/uinew/component/forms";
import {FormattedMessage} from "react-intl";

export let YesNoRadio = ({name, value, setter, disabled}) => {
    return <div className={"mb-2 d-flex flex-row gap-3"}>
        <Radio name={name}
               onChange={setter}
               checked={value === false}
               value={false}
               currentValue={value}
               label={<FormattedMessage id={"common.no"} />}
               disabled={disabled}
        />

        <Radio name={name}
               onChange={setter}
               checked={value === true}
               value={true}
               currentValue={value}
               label={<FormattedMessage id={"common.yes"} />}
               disabled={disabled}
        />
    </div>
}

export let RadioButtons = ({
                               containerClassName,
                               options,
                               selectedValue,
                               disabled,
                               onChange,
                               textualContent = false
                           }) => {
    let [selectedLabel, setSelectedLabel] = useState()
    useEffect(() => {
        let selected = options.find(o => o.value === selectedValue)
        if (selected) {
            setSelectedLabel(selected.label)
        }
    }, [options]);

    return <>
        <div class={`d-flex pdf--hide ${containerClassName}`}>
            {options.map((option) => <div class="me-2">
                <input type="radio"
                       className={`btn-check`}
                       id={option.elementId}
                       value={option.value}
                       disabled={disabled}
                       checked={option.value === selectedValue}
                       onChange={(e) => onChange(e.target.value)}/>
                <label htmlFor={option.elementId}
                       className={`btn btn-outline-secondary px-2 ${textualContent ? 'w-100 text-wrap' : ''}`}>
                    <span class="mx-1">{option.label}</span>
                </label>
            </div>)}
        </div>
        <div className={"pdf--d-inline-block"}>
            {selectedLabel}
        </div>
    </>
}