import {FullWidthSection1} from "@commons/ui/component/Section";
import {FormattedMessage} from "react-intl";
import React from "react";
import {YouTubeVideosCarousel} from "@commons/uinew/domaincomponent/videosCarousel";
import {StoryblokComponent} from "@storyblok/react";

let VideoSwiper = ({blok}) => {
    return <div className={"position-relative"}>
        <FullWidthSection1 className={`${blok.Background}`}
                           rowClass={"g-3"}
                           padding={'sm'}
                           title={<FormattedMessage id={"common.video-carousel"}/>}
                           offset={blok.Offset}>
            <YouTubeVideosCarousel
                items={blok.Items.map(item =>
                    ({
                        youtubeVideoId: item.YoutubeVideoId,
                        thumbnailYoutubeVideoId: item.YoutubeCustomThumbnailUrl,
                        title: item.Title,
                        height: item.ItemsHeight,
                        thumbnailOffsetY: item.ThumbnailOffsetY
                    })
                )}
            />
        </FullWidthSection1>

        {blok.BackgroundWave && blok.BackgroundWave.map(bw => <StoryblokComponent blok={bw} zIndex={'0'} background={'white'}/>)}
    </div>
}

export default VideoSwiper