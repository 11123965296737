import React, {useCallback, useContext, useEffect, useState} from "react";
import Accordion from "react-bootstrap/Accordion";
import {useLocation, useNavigate} from "react-router-dom";
import {AccordionContext} from "react-bootstrap";
import {useAccordionButton} from "react-bootstrap/AccordionButton";
import {EMPTY_FUNCTION} from "@commons/infra/helpers";
import {CustomFormattedMessage} from "@commons/infra/component/CustomFormattedMessage";
import {FormattedMessage} from "react-intl";

let FaqAccordion = ({items = []}) => {
    let [activeKey, setActiveKey] = useState('');
    let location = useLocation()
    let navigate = useNavigate()

    useEffect(() => {
        setActiveKey(location.hash.substring(1))
    }, [location.hash]);

    let onClick = (hash) => {
        navigate(`${location.pathname}${location.search}#${hash}`);
    };

    return <Accordion activeKey={activeKey} onSelect={(s) => setActiveKey(s)} defaultActiveKey={activeKey}>
        {items.map((item) => {
            return <>
                <Accordion.Item className={"accordion-item bg-white shadow"} eventKey={item.hash}>
                    <AccordionHeading active={activeKey === item.hash}
                                      eventKey={item.hash}
                                      text={<>
                                          {item.titleMessage && <FormattedMessage {...item.titleMessage}/>}
                                          {item.title && item.title}
                                      </>}
                                      onClick={() => onClick(item.hash)}
                                      id={`q${item.hash}`}
                    />
                    <Accordion.Collapse eventKey={item.hash}>
                        <div className={"accordion-body"} id={`${item.hash}`}>
                            {item.textMessage && <CustomFormattedMessage {...item.textMessage}/>}
                            {item.text && item.text}
                        </div>
                    </Accordion.Collapse>
                </Accordion.Item>
            </>
        })}
    </Accordion>
};

export let EXCLUDE_FROM_ACCORDION_CLICK = 'exclude-from-accordion-click'
export let AccordionHeading = ({active = null, text, eventKey, rightCorner = <></>, onClick = EMPTY_FUNCTION}) => {
    let context = useContext(AccordionContext);

    let resolveIsActive = useCallback((active, context) => active ? active : context.activeEventKey === eventKey, [eventKey])
    let [isActive, setIsActive] = useState(resolveIsActive(active, context))
    useEffect(() => {
        setIsActive(resolveIsActive(active, context))
    }, [active, context, resolveIsActive])

    let accordionButton = useAccordionButton(eventKey);
    let onClick1 = e => {
        if (!e.target.classList.contains(EXCLUDE_FROM_ACCORDION_CLICK)) accordionButton(e)
        onClick()
    }

    return <h3 className="accordion-header" onClick={onClick1}>
        <button className={'accordion-button ' + (isActive ? '' : 'collapsed')}
                onClick={e => e.preventDefault()}>
            <div className={"flex-grow-1"}>
                {text}
            </div>
            {rightCorner && <div className={'me-3'}>
                {rightCorner}
            </div>}
        </button>
    </h3>
}

export default FaqAccordion;
