import React, {createRef, useState} from 'react';
import {tryLogout} from "@commons/domain/redux/actions";
import {connect} from "react-redux";
import {
    arrayPart,
    isLoggedIn,
    isLoggedInAsAdmin,
    isLoggedInAsDoctor,
    isLoggedInAsPatient,
    useCurrentUrlWithHash
} from "@commons/infra/helpers";
import {Offcanvas} from "react-bootstrap";
import PatientLinks, {backofficeLink, DoctorLinks, PublicLinks, StoryblokLinks} from "../../utility/links";
import {FormattedMessage, useIntl} from "react-intl";
import Logo from "@commons/ui/component/Logo";
import LocalizedLink, {useLocalizedRoute} from "@commons/infra/i18n/LocalizedLink";
import {LanguageSwitcher, useMobileLanguages} from "@commons/infra/i18n/LocalizedSwitcher";
import {useLogoutHandler, useStickyHeader} from "@commons/ui/hooks";
import Navigation from "@commons/ui/component/Navigation";
import {useStoryblokMapping} from "@commons/infra/storyblok/data";
import {HelloText} from "@commons/uinew/domaincomponent/onboarding/HelloText";
import {CommonPatientLinks} from "@commons/infra/links";
import {useLocation, useNavigate} from "react-router-dom";
import {JUST_LOGGED_OUT_HASH} from "@commons/ui/component/Restricted";
import ReservationCountdown from "@commons/ui/component/ReservationCountdown";

let mobileBreakpoint = 'sm';
let patientLinks = [
    {
        link: PatientLinks.profile.info(),
        messageId: 'pages.my-profile.page-title',
        icon: 'ai-user-check'
    },
    {
        link: CommonPatientLinks.profile.oauth(),
        messageId: 'pages.my-profile.oauth',
        icon: 'ai-globe'
    },
    {
        link: CommonPatientLinks.profile.surveys(),
        messageId: 'pages.my-surveys.page-title',
        icon: 'ai-note'
    },
    {
        link: CommonPatientLinks.profile.cardioDiary(),
        messageId: 'pages.cardio-diary.page-title',
        icon: 'ai-calendar-check'
    },
    {
        link: CommonPatientLinks.profile.notifications(),
        messageId: 'pages.notifications.page-title',
        icon: 'ai-bell'
    },
    {
        link: CommonPatientLinks.profile.termsAndConditions(),
        messageId: 'pages.terms-and-conditions.page-title',
        icon: 'ai-file-text'
    }
];


// this is actually LV only
const PublicHeader = function ({
                                   shadow = true,
                                   className = '',
                                   bgClassName = 'bg-white',
                                   variant = 'light',
                                   authentication,
                                   patientProfile,
                                   patientOngoingReservation,
                                   showCta = true,
                               }) {
    let {formatMessage} = useIntl()
    let {pathname} = useLocation()
    let navigate = useNavigate()
    let localizedRoute = useLocalizedRoute()
    let logoutHandler = useLogoutHandler()
    let storyblokData = useStoryblokMapping()
    let currentUrlWithHash = useCurrentUrlWithHash()

    let offcanvasContainerRef = createRef();
    let [offCanvasOpened, setOffCanvasOpened] = useState(false);

    let toggleOffCanvas = () => setOffCanvasOpened(!offCanvasOpened);
    let closeOffCanvas = () => setOffCanvasOpened(false);

    useStickyHeader();
    let mobileLanguages = useMobileLanguages();

    const doLogout = (e) => {
        navigate(currentUrlWithHash.get(JUST_LOGGED_OUT_HASH));
        e.preventDefault();
        logoutHandler();
    };

    let yourHealthItem = {
        text: formatMessage({id: 'components.public-header.your-health'}),
        className: "d-block d-lg-block",
        megamenu: [{
            title: formatMessage({id: 'components.public-header.health-issues'}),
            className: "d-block d-lg-block",
            items: arrayPart(storyblokData.getHealthIssues(), 1, 2).map(hi => ({
                text: hi.title,
                to: hi.link
            }))
        }, {
            continuation: true,
            className: "d-block d-lg-block",
            items: [
                ...arrayPart(storyblokData.getHealthIssues(), 2, 2).map(hi => ({
                    text: hi.title,
                    to: hi.link
                })),
                {
                    text: formatMessage({id: 'components.public-header.view-all-health-issues'}),
                    to: PublicLinks.storyblokHealthIssues(),
                    separator: true
                }
            ],
        }, {
            title: formatMessage({id: 'components.public-header.partners'}),
            items: [
                {
                    text: "InternetAptieka.lv",
                    to: PublicLinks.internetAptieka()
                },
                {
                    text: "MyFitness",
                    to: StoryblokLinks.myFitness()
                },
                {
                    text: "Balcia",
                    to: "/balcia"
                }
            ]
        }]
    };
    let howItWorksItem = {
        text: formatMessage({id: 'components.public-header.how-it-works'}),
        to: '#',
        className: "d-block d-lg-block",
        items: [
            {
                text: formatMessage({id: 'components.public-header.how-medon-consultations-work'}),
                to: PublicLinks.howItWorks()
            },
            {
                text: formatMessage({id: 'components.public-header.health-programs'}),
                to: PublicLinks.healthPrograms()
            },
            {
                text: formatMessage({id: 'components.public-header.corporate'}),
                to: PublicLinks.forCorporates()
            },
            {
                text: formatMessage({id: 'components.public-header.faq'}),
                to: PublicLinks.faq()
            },
        ]
    };
    let aboutItem = {
        text: formatMessage({id: 'components.public-header.about-us'}), to: PublicLinks.about()
    };
    let publicNavigation = <Navigation items={[
        {
            text: formatMessage({id: 'components.public-header.specialists'}),
            to: PublicLinks.storyblokSpecialities()
        },
        // {
        //     text: formatMessage({id: 'components.public-header.specialities'}),
        //     className: "d-block d-lg-block",
        //     megamenu: [
        //         {
        //             title: formatMessage({id: 'components.public-header.specialities'}),
        //             items: arrayPart(storyblokData.getSpecialities(), 1, 2).map(s => ({
        //                 text: s.title,
        //                 to: s.link
        //             }))
        //         },
        //         {
        //             continuation: true,
        //             items: [
        //                 ...arrayPart(storyblokData.getSpecialities(), 2, 2).map(s => ({
        //                     text: s.title,
        //                     to: s.link
        //                 })),
        //                 {
        //                     text: formatMessage({id: 'components.public-header.view-all-specialities'}),
        //                     to: PublicLinks.storyblokSpecialities(),
        //                     separator: true
        //                 }
        //             ]
        //         }
        //     ]
        // },
        yourHealthItem,
        howItWorksItem,
        aboutItem,
        {
            separator: true,
            text: <>
                <FormattedMessage id={"components.public-header.login"}/>
            </>,
            to: PublicLinks.login(),
            className: 'd-block d-lg-none',
        },
        {
            text: <>
                <FormattedMessage id={"components.public-header.register-1"}/>
            </>,
            to: PublicLinks.registration.patient(),
            className: 'd-block d-lg-none',
        },
        {
            text: <>
                <i className={"ai ai-globe me-2 d-none d-lg-block"}/>
                <FormattedMessage id={"components.public-header.languages"}/>
            </>,
            to: '#',
            className: 'd-block d-lg-none',
            items: mobileLanguages.getMobileLanguages()
        }
    ]} onLinkClick={closeOffCanvas}/>;

    let patientNavigation = <Navigation items={[
        {
            text: formatMessage({id: 'components.public-header.my-bookings'}),
            to: PatientLinks.booking.list()
        },
        {
            text: formatMessage({id: 'components.public-header.specialists'}),
            to: PublicLinks.doctors()
        },
        {...yourHealthItem, if: () => !patientOngoingReservation.exists},
        howItWorksItem,
        {
            text: <>
                <FormattedMessage id={"components.public-header.continue-reservation"}/>
                <span className={"text-muted ms-2"}>
                    <ReservationCountdown
                        reservedTill={patientOngoingReservation.appointment.reservedTill}/>
                </span>
            </>,
            to: PatientLinks.reservation.payment(patientOngoingReservation.appointment.doctorId, patientOngoingReservation.appointment.bookingId),
            if: () => patientOngoingReservation.exists
        },
        {...aboutItem, if: () => !patientOngoingReservation.exists},
        {
            text: <span className={"sensitive-data hw-not-allow"}>
                <i className={"ai ai-user me-2"}/> {patientProfile.name} {patientProfile.surname}
            </span>,
            to: '/my/profile',
            className: 'd-block d-lg-none',
            items: [...patientLinks.map(pl => ({
                text: formatMessage({id: pl.messageId}),
                to: pl.link
            })), {
                text: formatMessage({id: 'components.public-header.logout'}),
                onLinkClick: doLogout,
                separator: true
            }]
        }]} onLinkClick={closeOffCanvas}/>;
    return <>
        {/*{!authentication.isAuthenticated && <StickyTopBanner*/}
        {/*    href={"/sl/diabets-aptaukosanas"}*/}
        {/*    messageId={"stinky-banner.diabets.text"}*/}
        {/*/>}*/}
        <header
            className={`header navbar navbar-expand-lg navbar-${variant} navbar-sticky sticky-top ${shadow && 'navbar-box-shadow'} ${className} ${bgClassName}`}>
            <div className="container">
                {/* Burger */}
                <button className="navbar-toggler me-3" aria-labelledby="Navigation"
                    // aria-expanded={offCanvasOpened}
                        type="button"
                        onClick={toggleOffCanvas}>
                    <span className="navbar-toggler-icon"/>
                </button>

                {/* Logo */}
                <LocalizedLink
                    className={`navbar-brand ms-lg-0 flex-grow-1 flex-${mobileBreakpoint}-grow-0`}
                    to={isLoggedInAsPatient() ? PatientLinks.dashboard() : PublicLinks.home()}>

                    <Logo theme={variant === 'dark' ? 'white' : 'dark'}/>
                </LocalizedLink>

                {/* Offcanvas */}
                <div className={"navbar"} ref={offcanvasContainerRef}/>

                {/* Toolbar */}
                <div className="d-flex align-items-center order-lg-3 ms-lg-auto">

                    <LanguageSwitcher/>

                    {!isLoggedIn() && <LocalizedLink
                        className={`nav-link ${variant === 'dark' && 'text-light'} nav-link fs-sm text-nowrap p-2 fw-medium d-none d-lg-block`}
                        to={PublicLinks.login()}>
                        <i className="ai-user fs-xl me-2 align-middle"/>
                        <FormattedMessage id={"components.public-header.login"}/>
                    </LocalizedLink>}

                    {isLoggedInAsPatient() && <>
                        <div className="dropdown d-none d-lg-block">
                            <a className="nav-link d-flex align-items-center p-0" href="/#"
                               onClick={e => e.preventDefault()}>
                                <div className="ps-2">
                                    <div className={" dropdown-toggle"}>
                                        <HelloText name={patientProfile.name}/>
                                    </div>
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                                {patientLinks.map(pl => <LocalizedLink to={pl.link} className="dropdown-item">
                                    <i className={`${pl.icon} fs-lg opacity-70 me-2`}/>
                                    <FormattedMessage id={pl.messageId}/>
                                </LocalizedLink>)}
                                <div className="dropdown-divider"/>
                                <a className="dropdown-item" href={"/#"}
                                   onClick={(e) => doLogout(e)}>
                                    <i className="ai-logout fs-lg opacity-70 me-2"/>
                                    <FormattedMessage id={"components.public-header.logout"}/>
                                </a>
                            </div>
                        </div>
                    </>}

                    {isLoggedInAsDoctor() && <LocalizedLink
                        className={`nav-link ${variant === 'dark' && 'text-light'} fs-sm text-nowrap ms-2 me-3 fw-500`}
                        to={DoctorLinks.dashboard()}>
                        Ārsta portāls
                    </LocalizedLink>}

                    {isLoggedInAsAdmin() &&
                        <a className={`nav-link ${variant === 'dark' && 'text-light'} fs-sm text-nowrap me-3 fw-500`}
                           href={backofficeLink}>
                            Backoffice
                        </a>}

                    {(isLoggedInAsDoctor() || isLoggedInAsAdmin()) && <a href='/#'
                                                                         onClick={(e) => doLogout(e)}
                                                                         className={`nav-link ${variant === 'dark' && 'text-light'} fs-sm text-nowrap d-flex align-items-center ms-2 p-2 me-lg-0 fw-500`}>

                        <i className="ai-logout fs-xl me-1"/>
                        <FormattedMessage id={"components.public-header.logout"}/>
                    </a>}

                    {!isLoggedIn() && showCta
                        && !pathname.includes("/balcia")
                        && !pathname.includes("/ergo")
                        && !pathname.includes("/vrachi")
                        && !pathname.includes("/arsti")
                        && !pathname.includes("/doctors")
                        && !pathname.includes("/darbiniekiem")
                        && !pathname.includes("/company/select-time")
                        && <LocalizedLink
                            className={`btn btn-${variant === 'dark' ? 'light' : 'primary'} btn-sm px-4 ms-4`}
                            to={PublicLinks.doctors()}
                            onClick={e => {
                                e.preventDefault()
                                if ('posthog' in window) {
                                    window.posthog.capture('public-header.cta-click')
                                }
                                navigate(localizedRoute.create(PublicLinks.doctors()))
                            }}>
                            <FormattedMessage id={"components.public-header.register"}/>
                        </LocalizedLink>}
                </div>


                {/* Navigation */}
                <Offcanvas container={offcanvasContainerRef}
                           renderStaticNode={true}
                           className={"offcanvas-collapse"}
                           show={offCanvasOpened}
                           responsive="lg"
                           onHide={() => setOffCanvasOpened(false)}>

                    <div className="offcanvas-header pt-4">
                        <Logo/>

                        <button type="button" className="btn btn-link text-dark btn-xl ps-0 text-decoration-none p-0"
                                onClick={closeOffCanvas}>
                            <i className={"ai ai-cross-alt fs-lg"}/>
                        </button>
                    </div>

                    <div className="offcanvas-body pt-0">
                        {!isLoggedInAsPatient() ? publicNavigation : patientNavigation}
                    </div>
                </Offcanvas>
            </div>
        </header>
    </>;
};

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication,
        patientProfile: state.patient.profile,
        patientOngoingReservation: state.patient.ongoingReservation
    }
};

const mapDispatchToProps = {tryLogout};

export default connect(mapStateToProps, mapDispatchToProps)(PublicHeader)
