/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */
import type {Result} from "./schemas";

import {axiosProvider} from "../../../commons/src/infra/http/axios/axiosProvider";

type SecondParameter<T extends (...args: never) => unknown> = Parameters<T>[1];

export const getAppointment = (
    id: string,
    options?: SecondParameter<typeof axiosProvider>,
) => {
    return axiosProvider<Result>(
        {url: `/frontend/appointments/${id}`, method: "GET"},
        options,
    );
};

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export type GetAppointmentResult = NonNullable<
    Awaited<ReturnType<typeof getAppointment>>
>;
