import {SpecialitiesSwiperSection} from "@commons/uinew/domainsection/swipers/SpecialitiesSwiperSection";
import {BackgroundWave} from "@commons/uinew/domaincomponent/svg";
import {SameBackgroundSection} from "@commons/uinew/section/DifferentBackgroundSection";
import React from "react";
import {RichText} from "@commons/infra/storyblok/richText";
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";
import {extractImageDimensions} from "@commons/infra/storyblok/helpers";
import {StoryblokComponent} from "@storyblok/react";

export let Cards2Section = ({blok}) => {
    return <SameBackgroundSection className={"position-relative"}>
        <h2>
            {blok.Title}
        </h2>
        <SpecialitiesSwiperSection
            items={blok.Cards.map(c => {
                return {
                    imgSrc: c.Illustration.filename,
                    imgAlt: c.Illustration.alt,
                    link: parseI18nLink(c.Link),
                    title: c.Title,
                    text: <RichText content={c.Content}/>,
                    style: {
                        maxHeight: extractImageDimensions(c.Illustration.filename).height,
                        maxWidth: extractImageDimensions(c.Illustration.filename).width,
                    }
                }
            })}
        />

        {blok.BackgroundWave.map(bw => <StoryblokComponent blok={bw} />)}
    </SameBackgroundSection>
}