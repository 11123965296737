// export let SPECIALITY_DERMATOR


export let SB_SPECIALITY_DERMATOLOGS = 'dermatologs'
export let SB_SPECIALITY_ENDOKRINOLOGS = 'endokrinologs'
export let SB_SPECIALITY_GIMENES_ARSTS = 'gimenes-arsts'
// export let SB_SPECIALITY_GIMENES_ARSTS = 'gimenes-visparejas-prakses-arsts'
export let SB_SPECIALITY_GINEKOLOGS = 'ginekologs'
export let SB_SPECIALITY_KARDIOLOGS = 'kardiologs'
export let SB_SPECIALITY_NEIROLOGS = 'neirologs'
export let SB_SPECIALITY_ONKOLOGS = 'onkologs'
export let SB_SPECIALITY_PEDIATRS = 'pediatrs'
export let SB_SPECIALITY_PSIHISKAS_VESELIBAS_SPECIALISTI = 'psihiskas-veselibas-specialisti'
export let SB_SPECIALITY_REIMATOLOGS = 'reimatologs'
export let SB_SPECIALITY_UROLOGS = 'urologs'
export let SB_SPECIALITY_UZTURA_SPECIALISTS = 'uztura-specialists'
export let SB_SPECIALITY_TRAUMOTOLOGS = 'traumatologs'
