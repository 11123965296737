import LazySwiper from "@commons/uinew/component/swiper/LazySwiper";

let DEFAULT_BREAKPOINTS = {
    1: { // sm
        slidesPerView: 1, spaceBetween: 20,
    },
    768: { // md
        slidesPerView: 2, spaceBetween: 20,
    },
    992: { // lg
        slidesPerView: 3, spaceBetween: 20,
    },
};

export let DEFAULT_BREAKPOINTS_4 = {
    1: { // sm
        slidesPerView: 1, spaceBetween: 20,
    },
    768: { // md
        slidesPerView: 3, spaceBetween: 20,
    },
    992: { // lg
        slidesPerView: 4, spaceBetween: 20,
    },
};

export let DEFAULT_BREAKPOINTS_SM_2 = {
    1: { // sm
        slidesPerView: 1.5, spaceBetween: 20,
    },
    768: { // md
        slidesPerView: 3, spaceBetween: 20,
    },
    992: { // lg
        slidesPerView: 4, spaceBetween: 20,
    },
};

export let DEFAULT_BREAKPOINTS_SM_2_LG_45 = {
    1: { // sm
        slidesPerView: 1.5, spaceBetween: 20,
    },
    768: { // md
        slidesPerView: 3, spaceBetween: 20,
    },
    992: { // lg
        slidesPerView: 4.5, spaceBetween: 20,
    },
};

export let createBreakpoints = (sm, md, lg) => ({
    1: { // sm
        slidesPerView: sm, spaceBetween: 20,
    },
    768: { // md
        slidesPerView: md, spaceBetween: 20,
    },
    992: { // lg
        slidesPerView: lg, spaceBetween: 20,
    },
})

export let CustomSwiper = ({className = '', children, items, slideClassName, ...props}) => {
    return <LazySwiper
        centeredSlides={false}
        centerInsufficientSlides={true}
        grabCursor={true}
        autoHeight={true}
        pagination={true}
        className={`pb-5 ${className}`}
        breakpoints={DEFAULT_BREAKPOINTS}
        items={items}
        slideClassName={slideClassName}
        {...props}>
        {children}
    </LazySwiper>
}


export let SWIPER_OFFSET = 12.6

/**
 * Compensates .container horizontal padding with negative margins
 * "slidesOffsetX" should also be set on <Swiper>
 */
export let SwiperWrapper = ({children}) => {
    return <div>
        {children}
    </div>
}

export let defaultSwiperSettings = ({wrapperClass = '', className = ''} = {}) => ({
    // cssMode: true,
    mousewheel: {
        enabled: true,
        forceToAxis: true,
        sensitivity: 0.1,
        thresholdDelta: 25,
        thresholdTime: 300
    },
    centeredSlides: false,
    centerInsufficientSlides: false,
    grabCursor: false,
    autoHeight: true,
    pagination: {
        enabled: true,
        clickable: true,
    },
    className: `pb-5 ${className}`,
    wrapperClass
    // breakpoints: DEFAULT_BREAKPOINTS,
})