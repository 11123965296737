import React, {useEffect, useState} from "react";
import {StoryblokComponent} from "@storyblok/react";
import {useIntl} from "react-intl";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import PublicHeader from "../../../../../../portal-latvia/src/common/component/PublicHeader";
import PublicFooter from "../../../../../../portal-latvia/src/common/component/PublicFooter";
import {LatvianMetaLinksHelmet} from "@commons/infra/i18n/MetaLinksHelmet";
import {useSbDataFetcher} from "@commons/infra/storyblok/helpers";


let StoryblokLandingPage = ({slugPrefix}) => {
    let {landingId} = useParams();

    return <StoryblokSpecificLandingPage landingId={landingId} slugPrefix={slugPrefix}/>
}

export let StoryblokSpecificLandingPage = ({landingId, slugPrefix}) => {
    let {locale} = useIntl()

    let [story, setStory] = useState()
    let sbDataFetcher = useSbDataFetcher()

    let slug = `${slugPrefix}${landingId}`;
    useEffect(() => {
        sbDataFetcher(`${slug}_${locale}`, `cdn/stories/${slug}`, {language: locale})
            .then((r) => {
                setStory(r)
            })
    }, [slug, locale]);

    return <>
        {story && <>
            <Helmet>
                <title>{story.content.PageTitle}</title>
                <meta name="description" content={story.content.MetaDescription}/>
            </Helmet>
            <LatvianMetaLinksHelmet/>

            <PublicHeader
                bgClassName={story.content.HeaderBackgroundClass}
                shadow={story.content.HeaderShadow}
                // variant={story.content.HeaderBackgroundClass === 'bg-dark' ? 'dark' : 'light'}
            />

            <StoryblokComponent blok={story.content}/>

            <PublicFooter/>
        </>}
    </>
}

export default StoryblokLandingPage